import "core-js/modules/es.array.push.js";
import TabBar from '../common/TabBar';
export default {
  components: {
    TabBar
  },
  data() {
    return {
      checkedGoods: ['1', '2', '3'],
      newsList: [],
      bannerList: [],
      loading: false,
      finished: false
    };
  },
  created() {
    this.loadBanner();
    this.loadData();
    this.loading = false;
  },
  computed: {},
  methods: {
    // 加载数据
    async loadData() {
      // this.loading = true
      try {
        this.$axios.get('/news/list/').then(response => {
          this.newsList = response.data;
        });
        // Notify({
        //     message: '数据加载成功',
        //     type: 'success'
        // });
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
        this.finished = true;
      }
    },
    async loadBanner() {
      // this.loading = true
      try {
        this.$axios.get('/banner/list/').then(response => {
          this.bannerList = response.data;
        });
        // Notify({
        //     message: '数据加载成功',
        //     type: 'success'
        // });
      } catch (error) {
        console.error(error);
      }
    },
    autoColor(importance) {
      if (importance == 1) return '#C0C0C0';
      if (importance == 2) return '#70DB93';
      if (importance == 3) return '#D9D919';
      if (importance == 4) return '#FFA500';
      if (importance == 5) return '#FF0000';
    },
    go(link) {
      if (link == null || link == '') return;
      if (link.startsWith('/')) {
        this.$router.push(link);
      } else {
        window.location.href = link;
      }
    }
  }
};